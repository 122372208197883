/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.center-text {
    text-align: center;
}

.label-font { font-weight: bold; }

.odd-row { background-color: #dadadac2; }

/* Margin classes */
.ml-1 { margin-left: 5px; }
.ml-2 { margin-left: 10px; }

.mr-1 { margin-right: 5px; }
.mr-2 { margin-right: 10px; }
.mr-3 { margin-right: 15px; }

.mt-10 { margin-top: 10px; }
.mt-15 { margin-top: 15px; }
.mt-20 { margin-top: 20px; }

.mb-0 { margin-bottom: 0; }
.mb-1 { margin-bottom: 5px; }
.mb-10 { margin-bottom: 10px; }
.mb-15 { margin-bottom: 15px; }
.mb-20 { margin-bottom: 20px; }

.my-0 { margin-top: 0px; margin-bottom: 0px; }
.my-1 { margin-top: 5px; margin-bottom: 5px; }
.my-2 { margin-top: 10px; margin-bottom: 10px; }
.my-3 { margin-top: 15px; margin-bottom: 15px; }

.mx-auto {margin-left: auto; margin-right: auto;}

.pr-1 { padding-right: 5px; }
.pr-2 { padding-right: 10px; }
.pr-3 { padding-right: 15px; }
.pr-4 { padding-right: 20px; }

.pl-1 { padding-left: 5px; }
.pl-2 { padding-left: 10px; }
.pl-3 { padding-left: 15px; }
.pl-4 { padding-left: 20px; }

.py-1 { padding-top: 5px; padding-bottom: 5px; }
.py-2 { padding-top: 10px; padding-bottom: 10px; }

.pt-1 { padding-top: 5px;}
.pt-2 { padding-top: 10px;}

.full-width { width: 100%; }

p.detail-text { margin: 0 !important; }

.example-spacer { flex: 1 1 auto; }

.table-container-600 {
    max-height: 600px;
    overflow-x: auto;
}

.table-container-500 {
    max-height: 500px;
    overflow-x: auto;
}

.missing-bid-text.ag-cell-not-inline-editing {
    color: red;
}

.no-bid-text.ag-cell-not-inline-editing {
    color: orange;
}

.changed-bid-cell.ag-cell-not-inline-editing {
    background-color: #EAC252;
    color: black;
    font-weight: bold;
}