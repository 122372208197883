@use '@angular/material' as mat;
@import '~@angular/material/theming';
@import './material-component-theme.scss';

@include mat-core();

// Light theme Blue
$csd-app-light-blue-primary: mat-palette($mat-blue, 600);
$csd-app-light-blue-accent: mat-palette($mat-light-blue, 500);
$csd-app-light-blue-theme: mat-light-theme($csd-app-light-blue-primary, $csd-app-light-blue-accent);

// Light theme Teal
$csd-app-light-teal-primary: mat-palette($mat-teal, 500);
$csd-app-light-teal-accent: mat-palette($mat-teal, 'A700');
$csd-app-light-teal-theme: mat-light-theme($csd-app-light-teal-primary, $csd-app-light-teal-accent);

// Light theme Black
$csd-app-light-black-primary: mat-palette($mat-grey, 900);
$csd-app-light-black-accent: mat-palette($mat-deep-orange, 'A100');
$csd-app-light-black-theme: mat-light-theme($csd-app-light-black-primary, $csd-app-light-black-accent);

// Light theme Indigo
$csd-app-light-indigo-primary: mat-palette($mat-indigo, 400);
$csd-app-light-indigo-accent: mat-palette($mat-indigo, 300);
$csd-app-light-indigo-theme: mat-light-theme($csd-app-light-indigo-primary, $csd-app-light-indigo-accent);

// Dark theme Blue
$csd-app-dark-blue-primary: mat-palette($mat-blue, 600);
$csd-app-dark-blue-accent: mat-palette($mat-light-blue, 500);
$csd-app-dark-blue-theme: mat-dark-theme($csd-app-dark-blue-primary, $csd-app-dark-blue-accent);

// Dark theme Teal
$csd-app-dark-teal-primary: mat-palette($mat-teal, 500);
$csd-app-dark-teal-accent: mat-palette($mat-teal, 'A700');
$csd-app-dark-teal-theme: mat-dark-theme($csd-app-dark-teal-primary, $csd-app-dark-teal-accent);

// Dark theme Black
$csd-app-dark-black-primary: mat-palette($mat-grey, 900);
$csd-app-dark-black-accent: mat-palette($mat-deep-orange, 'A100');
$csd-app-dark-black-theme: mat-dark-theme($csd-app-dark-black-primary, $csd-app-dark-black-accent);

// Dark theme Indigo
$csd-app-dark-indigo-primary: mat-palette($mat-indigo, 400);
$csd-app-dark-indigo-accent: mat-palette($mat-indigo, 300);
$csd-app-dark-indigo-theme: mat-dark-theme($csd-app-dark-indigo-primary, $csd-app-dark-indigo-accent);


// Primary settings
$primary: mat-color($csd-app-light-blue-theme);
$primary-light: mat-color($csd-app-light-blue-theme, 800);
$primary-dark: mat-color($csd-app-dark-blue-theme);
$mat-text-color: mat-color($csd-app-light-blue-theme, default-contrast);


.lightBlueTheme {
    @include angular-material-theme($csd-app-light-blue-theme);
    @include component-themes($csd-app-light-blue-theme);
}

.lightTealTheme {
    @include angular-material-theme($csd-app-light-teal-theme);
    @include component-themes($csd-app-light-teal-theme);
}

.lightBlackTheme {
    @include angular-material-theme($csd-app-light-black-theme);
    @include component-themes($csd-app-light-black-theme);
}

.lightIndigoTheme {
    @include angular-material-theme($csd-app-light-indigo-theme);
    @include component-themes($csd-app-light-indigo-theme);
}

.darkBlueTheme {
    @include angular-material-color($csd-app-dark-blue-theme);
    @include component-themes($csd-app-dark-blue-theme);
}

.darkTealTheme {
    @include angular-material-color($csd-app-dark-teal-theme);
    @include component-themes($csd-app-dark-teal-theme);
}

.darkBlackTheme {
    @include angular-material-color($csd-app-dark-black-theme);
    @include component-themes($csd-app-dark-black-theme);
}

.darkIndigoTheme {
    @include angular-material-color($csd-app-dark-indigo-theme);
    @include component-themes($csd-app-dark-indigo-theme);
}

.mat-success {
    background-color: #1e9622;
    color: #fff;
}

.csd-snack {
    max-width: 24vw !important;
}

.snack-success {
    background: #C8E6C9 !important;
    color: #224a23 !important;
    border: solid #439446;
    border-width: 0 0 0 6px;
}

.snack-warn {
    background: #FFECB3 !important;
    color: #6d5100 !important;
    border: solid #d9a300;
    border-width: 0 0 0 6px;
}

.snack-info {
    border: solid #8a8a8a;
    border-width: 0 0 0 6px;
}

.snack-error {
    background: #FFCDD2 !important;
    color: #73000c !important;
    border: solid #e60017;
    border-width: 0 0 0 6px;
}