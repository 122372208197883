@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);
  
    // Get the primary/accent color palette from the color-config.
    $primary-palette: map.get($color-config, 'accent');
    // $primary-palette: map.get($color-config, 'primary');
    // $contrast-palette: map.get($color-config, '500-contrast');

  
    .mat-table-header-primary {
      // Read the 500 hue from the primary color palette.
      background-color: mat.get-color-from-palette($primary-palette);
      th, td, mat-header-cell, mat-footer-cell, .mat-sort-header-arrow {
          color: mat.get-color-from-palette($primary-palette, 'default-contrast');
      }
    }

    mat-toolbar.csd-shadow {
      box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.5);
    }
    
    mat-toolbar.test {
        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.5);
    }
    
    mat-toolbar.csd-height {
        min-height: 50px;
    }
    
    mat-toolbar-row.csd-height {
        height: 50px;
    }

    .csd-header-form-field.mat-focused {      
      .mat-form-field-ripple {
        background-color: mat.get-color-from-palette($primary-palette, 'default-contrast');
      }
      .mat-form-field-label {
        color: mat.get-color-from-palette($primary-palette, 'default-contrast');
      }
    }

    .light-padding.mat-form-field-appearance-outline {
      .mat-form-field-wrapper {
        padding-bottom: 1px;
      }
    }

    .highlight.mat-form-field-appearance-outline:not(.mat-focused):not(.mat-form-field-invalid) {
      .mat-form-field-wrapper {
        color: black; 
        font-weight: bold; 
      }

      .mat-form-field-outline {
        background-color: #ffff01b9;
        border-radius: 5px;
      }
    }
  }

@mixin component-themes($theme) {
    // $color-config: mat.get-color-config($theme);
    // @if $color-config != null {
    //     @include color($theme);
    // }

    @include color($theme);
}